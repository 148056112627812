import { Menu, Store, Tractor, X } from "lucide-react"
import logo from "../../assets/logos/logo.svg"
import { Button } from "../ui/button"
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from "../ui/drawer"

export default function NavBar(props:any) {

  return (
    <div className="container flex justify-between items-center mb-10 px-8 md:px-24 2xl:px-0 mx-auto">
      <div className="flex items-center">
        <a href="/">
          <img
            src={logo}
            height={40}
            alt="Mullo"
          />
        </a>
      </div>

      <div className="flex gap-2 xl:gap-3">
        {!props.buyer ? 
          <Button className="hidden lg:flex" variant="ghost" href="/"><Store className="mr-1" /> Je suis acheteur</Button> 
          : 
          <Button className="hidden lg:flex" variant="ghost" href="/vendre-en-local"><Tractor className="mr-1" /> Je suis producteur</Button>
        }
        {!props.blog && <Button className="hidden lg:flex" href="/blog" variant="ghost">Notre blog</Button>}
        <Button 
          className="hidden lg:flex" 
          variant="ghost"
          href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact"
        >
          Nous contacter
        </Button>
        <Button className="hidden sm:flex" variant="destructive" href="https://appro.mullo.fr/inscription">Inscription</Button>
        <Button className="hidden sm:flex" variant="secondary" href="https://appro.mullo.fr/connexion">Connexion</Button>

        <div className="block lg:hidden">
          <Drawer direction="right">
            <DrawerTrigger asChild>
              <Button variant="ghost"><Menu size={28} className="text-secondary-dark hover:text-white" /></Button>
            </DrawerTrigger>
            <DrawerContent>
              <div className="flex flex-1 flex-col">
                <DrawerHeader>
                  <div>
                    <DrawerTitle>Move Goal</DrawerTitle>
                    <DrawerDescription>Set your daily activity goal.</DrawerDescription>
                  </div>
                  <div className="ml-10">
                    <DrawerClose>
                      <Button variant="ghost" className="w-10 h-10 p-0 rounded-full">
                        <X size={32} />
                      </Button>
                    </DrawerClose>
                    {/* <Button className=""></Button> */}
                  </div>
                </DrawerHeader>
                <div className="p-4 pb-0 flex-1 flex flex-col gap-4">
                  {!props.buyer ? 
                    <Button className="w-full" variant="ghost" href="/acheter-local"><Store className="mr-1" /> Je suis acheteur</Button> 
                    : 
                    <Button className="w-full" variant="ghost" href="/"><Tractor className="mr-1" /> Je suis producteur</Button>
                  }
                  {!props.blog && <Button className="w-full" href="/blog" variant="ghost">Notre blog</Button>}
                  <Button  
                    className="w-full"
                    variant="ghost"
                    href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact"
                  >
                    Nous contacter
                  </Button>
                </div>
                <DrawerFooter>
                  <Button className="w-full" variant="destructive" href="https://appro.mullo.fr/inscription">Inscription</Button>
                  <Button className="w-full" variant="secondary" href="https://appro.mullo.fr/connexion">Connexion</Button>
                </DrawerFooter>
              </div>
            </DrawerContent>
          </Drawer>
        </div>
      </div>

    </div>
  )
}