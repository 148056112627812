import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Page from './app/page';
import Blog from './app/blog';
import BlogMain from './app/blog/blogMain';
import CategoryArticles from './app/blog/categoryArticle';
import SingleArticle from './app/blog/singleArticle';
import Buyer from './app/buyer';
import Team from './components/team';
import Member from './components/team/member';
import PostSignUp from './app/postSignUp';
const App: React.FC = () => {

  return (
    <Router>
      <Routes>
        {/* <Route path='*' Component={NotFound} /> */}
        <Route path='/' element={<Buyer />} />
        <Route path='/pre-inscription' element={<PostSignUp />} />
        <Route path='/vendre-en-local' element={<Page />} />
        <Route path='/blog' element={<Blog />} >
          <Route path="" element={<BlogMain />}/>
          <Route path=":id/:slug" element={<SingleArticle />}/>
          <Route path="categories/:id/:slug" element={<CategoryArticles />}/>
        </Route>
        {/* TEAM MEMBERS */}
        <Route path='/equipe' element={<Team />} >
          <Route path=':teamId/:slug' element={<Member />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
