import Full from "../../assets/images/diagram/mullo-full.png"
import topMobile from "../../assets/images/diagram/groupe-prod.png"
import { Button } from "../ui/button"
import NavBar from "../navigation/navBar"

export default function HomeSection(props:any) {

  return (
    <section className={`relative min-h-screen flex justify-between items-center flex-col bg-${props.color}`}>
      <div className="w-full mt-10">
        <NavBar buyer={props.buyer} />
      </div>

      <div className="container px-8 md:px-24 2xl:px-0 mx-auto flex flex-col-reverse md:flex-row space-between items-center">
        <div className="max-w-xl md:pr-15 flex-1">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-secondary-dark">
            {props.buyer ? 
            "Une plateforme digitale pour des circuits courts plus simples et efficaces" 
              : 
            "Maximisez la vente de vos produits en circuits courts à l'aide de circuits de livraison optimisés."}
          </h1>
          <p className="text-lg md:text-xl mt-3 md:mt-6 mb-4 md:mb-8">
            {props.buyer ? 
              "Simplifiez votre approvisionnement en produits locaux avec une logistique optimisée et des prix compétitifs." 
            : 
              "Simplifiez vos ventes et touchez plus de clients tout en profitant d'un système de livraison efficace et sans contraintes supplémentaires."
            }
          </p>
          <Button 
            variant={`secondary`} 
            href="https://appro.mullo.fr/inscription"
          >
            {props.buyer ? "S’approvisionner en ultra local" : "Rejoindre une place de marché"}
          </Button>
        </div>

        <div className="mb-10 md:hidden">
          <img
            src={topMobile}
            className="mt-10"
            alt="Regroupement producteurs"
          />
        </div>

        <div className="hidden md:block flex-2">
          <img
            src={Full}
            className="ml-5 lg:ml-16 xl:ml-24 object-contain mb-5 max-w-sm md:max-w-md xl:max-w-lg"
            alt="Regroupement producteurs"
          />
        </div>
      </div>

      <div></div>
    </section>
  )
}