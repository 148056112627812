import Section from "../components/sections";
import HomeSection from "../components/sections/home";
import groupProd from "../assets/images/diagram/groupe-prod.png";
import transport from "../assets/images/diagram/collecte.png";
import buyer from "../assets/images/diagram/livraison.png";
import FullCard from "../components/cards/fullCard";
import Partners from "../components/sections/partners";

export default function Home() {
  return (
    <div>
      <div className="snap-y snap-mandatory overflow-y-scroll h-screen flex-grow z-0">
        <div className="snap-always snap-center" id="page1">
          <HomeSection color="primary" />
        </div>
        <div className="snap-always snap-center" id="page2">
          <Section 
            id="2" 
            color="bg-secondary-lighter"
            textColor="text-secondary-dark"
            imgUrl={groupProd}
            texts={{
              title: "Unissez vos forces pour renforcer vos ventes et simplifier la logistique",
              subTitle: "Une solution transparente pour rassembler les productions et maximiser vos opportunités commerciales."
            }}
          />
        </div>
        <div className="snap-always snap-center" id="page3">
          <Section 
            id="2" 
            color="bg-secondary-light" 
            textColor="text-secondary-foreground"
            imgUrl={transport}
            texts={{
              title: "Transports optimisés en point de collecte chez vous ou l'un de vos confrères",
              subTitle: "Gagnez en efficacité tout en restant concentré sur votre production, avec une logistique optimisée et sans complexité."
            }}
          />
        </div>
        <div className="snap-always snap-center" id="page4">
          <Section 
            id="3" 
            color="bg-primary"
            textColor="text-secondary-dark"
            imgUrl={buyer}
            texts={{
              title: "Mutualisez votre clientèle et augmentez votre chiffre d’affaire",
              subTitle: "Sans effort supplémentaire : on s’interface au fur et à mesure avec vos logiciels de vente favoris !"
            }}
            full
          />
        </div>
        <div className="snap-always snap-start" id="page5">
          <div className="container px-8 md:px-24 2xl:px-0 py-8 md:py-24">
            <FullCard />
          </div>
          <Partners />
        </div>
      </div>
    </div>
    
  )
}