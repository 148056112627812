import { Button } from "../components/ui/button";
import Aura from "../assets/images/visuals/Aura.png"

export default function PostSignUp() {
  return (
    <div className="bg-primary">
      <div className="container min-h-screen flex flex-col items-center pt-20">
        
        <div className="flex items-end max-w-2xl ml-44">
          <img
            src={Aura}
            alt="Région Auvergne-Rhône-Alpes"
            className="max-w-sm mb-[-75px]"
          />
        </div>

        <div className="bg-primary-lighter rounded-lg px-5 sm:px-10 py-10 max-w-2xl">
          <h2 className="font-bold text-sm md:text-md text-destructive mb-3 text-center">AUVERGNE-RHÔNE-ALPES</h2>
          <h1 className="text-center text-xl md:text-3xl font-bold text-secondary-dark mb-5">
            Vous êtes invités à vous pré-inscrire sur Mullo !
          </h1>
          <p className="text-center text-md md:text-lg">
            À partir du 17 février commandez en direct des producteurs de votre région grâce à notre maillage 🥳
          </p>
          
          <div className="flex justify-center">
            <Button className="mt-5" variant="secondary" href="https://appro.mullo.fr/inscription">Je me pré-inscris maintenant</Button>
          </div>
        </div>

      </div>
    </div>
  )
}