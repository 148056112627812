// import cygogne from "../../assets/logos/cygogne.png";
import Tdt from "../../assets/logos/tdest.png";
// import paris from "../../assets/logos/paris.png";
import gaultetmillau from "../../assets/logos/gaultetmillau.png";
import liveforgood from "../../assets/logos/liveforgood.png";

export default function Partners() {
  const partners = [
    {
      picture: Tdt,
      name: 'Le Tour des Terroirs',
      description: 'Association du bien mangé'
    },
    // {
    //   picture: paris,
    //   name: 'Ville de Paris',
    //   description: 'Support institutionnel'
    // },
    {
      picture: gaultetmillau,
      name: 'Gault & Millau',
      description: 'Partenariat de la restauration'
    },
    {
      picture: liveforgood,
      name: 'BPI France',
      description: 'Partenariat financier'
    }
  ]

  return (
    <section className={`relative bg-secondary-lighterest p-24`}>
      <h4 className="text-center text-secondary-dark text-3xl font-bold mb-10">Ils sont aux côtés de Mullo</h4>
      <div className="flex flex-col md:flex-row justify-center items-center">
        {partners.map((p, i) => (
          <img
            key={i}
            src={p.picture}
            alt={p.name + ' | ' + p.description}
            className="mx-10 mb-10 md:mb-0"
          />
        ))}
      </div>
    </section>
  )
}