import Aura from "../../assets/images/visuals/Aura.png"
import { Button } from "../ui/button"

export default function FullCard(props:any) {

  return (
    <div className="flex flex-col lg:flex-row justify-between items-center p-10 lg:py-20 lg:px-16 bg-primary-lighter rounded-xl">
      <div className="flex-1">
        <h5 className="lg:mr-10 text-lg md:text-xl text-accent">Auvergne-Rhône-Alpes</h5>
        <h4 className="lg:mr-10 text-3xl md:text-4xl font-bold text-secondary-dark my-2">Ouverture imminente de nos livraisons</h4>
        <p className="lg:mr-10 text-lg mb-4">
          Dès le 11 février vous pourrez bénéficier du maillage local de producteurs sur Lyon, Grenoble (et bientôt Valence) et leurs alentours.
        </p>
        <div className="flex">
          {props.buyer ?
            <Button 
              variant="destructive" 
              className="mr-4" 
              href="https://appro.mullo.fr/inscription"
            >
              Se pré-inscrire
            </Button>
          : 
          <>
            <Button 
              variant="secondary" 
              className="mr-4" 
              href="https://appro.mullo.fr/inscription"
            >
              Rejoindre
            </Button>
            <Button variant="destructive" href="https://forms.fillout.com/t/v9auhK6gFYus?type=mulloNew">Créer</Button>
          </>
          }
        </div>
      </div>
      <div className="flex-1 mt-10 lg:mt-0">
        <img
          src={Aura}
          alt="Place de marché"
          className="rounded-xl"
        />
      </div>
    </div>
  )
}